<template>
    <div>
         <HomeCarousel2 :items="items"/>
         <SearchForm/>
        <div class="main pb-1">
            <div class="row">
              <div class="col-md-1"></div>
              <div class="post-content  col-md-5" align="center">
                  <p class="quote">  
                                    
                       {{$t("pages.about.slogan")}} 
                     <span>{{$t("pages.about.slogan2")}}</span>
                  </p>
              </div>
               <div class="post-content col-md-5" align="center">
                <a href=""></a>
                 <img src="/images/231-Logo-SIC.png" alt="Logo-SIC-BienesRaices" width="60%">
              </div>
          </div>    
          <div class="container wow zoomIn">
            <h1 class="section-title" data-animation-direction="from-bottom" data-animation-delay="50"> {{ $t("pages.home.title_properties") }} </h1>  
          </div>
        </div>
        <div class=" section-title text-center wow zoomIn">      
        <div class="container">
          <div id="property-listing" class="grid-style1 clearfix col-md-12" data-animation-direction="from-bottom" data-animation-delay="50">
            <Preloader v-if="!properties"/>
            <PropertyCard  
              v-else
              v-for="(item, i) in properties"
              :key="item.folio"
              :name="item.folio"
              :index="i"
              :item="item" 
            />
          </div>	
        </div>	
      </div>     
    </div>
    
    
</template>

<script>
import { mapGetters } from "vuex";
import SearchForm from "@/components/search/SearchForm.vue";
import PropertyCard from "@/components/properties/PropertyCard.vue";
import HomeCarousel2 from "@/components/carousel/HomeCarousel2.vue";
import Preloader from "@/components/layout/Preloader.vue";

export default {
  components: {
    HomeCarousel2,
    SearchForm,
    PropertyCard,
    Preloader
  },
  computed: {
    ...mapGetters({
      items:'_getHomeSlide',
      properties:'_getHomeProperties',
      error:'_getError',
    }),
  },
  created(){
    this.$store.dispatch('getHomeSlide');
    this.$store.dispatch('getHomeProperties');
  }
};
</script>

<style>
.listing-item {
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px;
  margin-bottom: 10px;
}
#propImg {
  width: 100%;
  height: 100%;
}

.navbar{
    height: 100% !important;
    width: 100% !important;

}
.highlight-props:before, .highlight-props:before, .highlight-props:after, .highlight-props:after {
  content: " ";
  position: absolute;
  bottom: 3px;
  left: 50%;
  margin-left: -50px;
  width: 100px;
  height: 1px;
  background-color: #d79b2b;
  -webkit-opacity: 0.5;
  -moz-opacity: 0.5;
  opacity: 0.5;
}
</style>
