<template>
<!-- BEGIN HIGHLIGHT -->
<div class="content">
    <div class="container">
        <div class="row">
            <div class="main col-sm-12">
                <div class="center">
                    <br>
                    <h2 class="section-highlight text-black" data-animation-direction="from-left" data-animation-delay="50">SIC Bienes Raíces ofrece:</h2>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                                                
                            <p v-if="info.about_us == 0 & info.about_us_eng == 0 || info.about_us == 0"
                            class="text-black  text-justify text-uppercase" data-animation-direction="from-left" data-animation-delay="650">
                            {{$t("pages.about.company.description")}}
                            </p>
                            <p v-else class="jump-line text-black  text-justify text-uppercase" data-animation-direction="from-left" data-animation-delay="650">
                            <About 
                                :aboutx="activeLocale == 'es' || !info.about_us_eng
                                ? info.about_us	 : info.about_us_eng"
                                />
                            </p>      
                        </div>
                    </div> 
                    <br>
                    <div class="row">
                     
                   

                        <div class="col-md-4">
                            <h2 class="text-black " data-animation-direction="from-left" data-animation-delay="250">
                                <b><b>{{$t("pages.about.vision.title")}}</b> </b>
                            </h2><br>
                            <p v-if="info.our_vision == 0 & info.our_vision_eng == 0"
                            class="text-black text-justify text-uppercase " data-animation-direction="from-left" data-animation-delay="650" >
                                {{$t("pages.about.vision.description")}}
                            </p>
                            <p v-else
                            class="jump-line text-black text-justify text-uppercase " data-animation-direction="from-left" data-animation-delay="650">
                            <Vision 
                                :visionx="activeLocale == 'es' || !info.our_vision_eng
                                ? info.our_vision	 : info.our_vision_eng"
                                />
                            </p>
                        </div>
                        <div class="col-md-4">
                            <h2 class="text-black " data-animation-direction="from-left" data-animation-delay="250">
                                <b>{{$t("pages.about.mision.title")}}</b> 
                            </h2><br>
                            <p v-if="info.our_mission == 0 & info.our_mission_eng == 0" 
                            class="text-black  text-justify text-uppercase" data-animation-direction="from-left" data-animation-delay="650">
                            {{$t("pages.about.mision.description")}}
                            </p>
                            <p v-else
                            class="jump-line text-black  text-justify text-uppercase" data-animation-direction="from-left" data-animation-delay="650">
                            <Mision 
                                :misionx="activeLocale == 'es' || !info.our_mission_eng
                                ? info.our_mission	 : info.our_mission_eng"
                                />
                            </p>
                        </div>
                        <div class="col-md-4">
                            <h2 class="text-black " data-animation-direction="from-left" data-animation-delay="250">
                                <b><b>{{$t("pages.about.values.title")}}</b> </b>
                            </h2><br>
                                <p v-if="info.our_values == 0 & info.our_values_eng == 0"
                                    class="text-black text-justify text-uppercase " data-animation-direction="from-left" data-animation-delay="650" >
                                    <ul >
                                    <li>
                                        {{$t("pages.about.values.example_one.description")}}

                                    </li>
                                    <li>{{$t("pages.about.values.example_two.description")}}</li>
                                    </ul>
                                    </p>
                                    <p v-else
                                    class="jump-line text-black text-justify text-uppercase " data-animation-direction="from-left" data-animation-delay="650">
                                    <Values 
                                        :valuesx="activeLocale == 'es' || !info.our_values_eng
                                        ? info.our_values	 : info.our_values_eng"
                                        />
                                    </p>
                        </div>
                    </div>
                    <br>
             
                </div>
                <div class="row">
                    <div class="col-sm-12 text-center mb-4">
                        <div class="dadFace">				
                            <ul class="social-networks" id="footer_social" style="float:none;"> 
                                <li><h4 class="text-black"><b>Búscanos en redes:</b></h4></li> <!-- || pinterest!='0' -->
                                <li><a  style="color:#3b5998 !important" data-toggle="tooltip" data-placement="top" title="sic360"  class="facebook" href="https://www.facebook.com/sic360/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li><a style="color:#3b5998 !important"  data-toggle="tooltip" data-placement="top" title="likasa.inmuebles"  class="facebook" href="https://www.facebook.com/likasa.inmuebles/" target="_blank"><i class="fab fa-facebook-f"></i></a>
                                </li>
                                <li><a style="color:#1da1f2 !important"  data-toggle="tooltip" data-placement="top" title="Blog"  class="twitter" href="https://sicbienesraices.com.mx/blog/"><i class="fa fa-globe"></i></a>
                                </li>
                            </ul>				
                        </div>
                    </div>
                            <br>
                    <p class="quote">

                        <span v-if="info.our_slogan == 0 & info.our_slogan_eng == 0" 
                        > </span
                        >
                        <span v-else class="jump-line">
                        <Slogan 
                            :sloganx="activeLocale == 'es' || !info.our_slogan_eng
                            ? info.our_slogan	 : info.our_slogan_eng"
                            />
                        </span>
                    </p>
                    <div class="col-sm-12 text-center">
                        <router-link to="/contacto" class="btn btn-default-color animate-from-left animation-from-left"
                                        >{{$t("pages.about.contact-button")}}</router-link
                                >
                    </div>
                   
                </div>
            </div>
        </div>
    </div>
</div>
<!-- END HIGHLIGHT -->

</template>



<script>
import { mapGetters } from "vuex";
import About from "@/components/about/About.vue";
import Slogan from "@/components/about/Slogan.vue";
import Mision from "@/components/about/Mision.vue";
import Vision from "@/components/about/Vision.vue";
import Values from "@/components/about/Values.vue";
export default {
    components: {
      About,
      Slogan,
      Mision,
      Vision,
      Values,

  },
  computed: {
    ...mapGetters({
      info: "_getInfoCompany",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
  },
  created() {
     window.scrollTo(0, 0);
    console.log("create footer");
  },
};
</script>
<style scoped>
.jump-line{
  word-wrap: break-word;
  white-space: pre-wrap;
}
.logo{
    width: 40% !important;
}

.mt-5{
    margin-top: 40px;
}
</style>