<template>
    <div id="contain_tarjet_property" class="item col-md-4">
        <!-- Set width to 4 columns for grid view mode only -->
        <div class="image">
            <a>				
                <a @click="goToProperty(item.folio, item.namePropertyEs)" class="btn btn-default"><i class="fa fa-file"></i> {{ $t("components.home_carousel.details")}}</a>				
            </a>
            <img :src="item.smallphoto" :alt="item.namePropertyEs" />
        </div>

      <div class="price">
          <i class="fa fa-home"></i>{{item.operation}}
          <span v-if="item.prices.sold!='0'">
            {{ item.prices.sold }} 
          </span>
          <span v-else-if="item.prices.opportunity_rent !='0'">
            {{ item.prices.opportunity_rent }} {{ item.currennamePerMonth }}
          </span>
          <span v-else-if="item.prices.rent !='0'">
            {{ item.prices.rent }} {{ item.currennamePerMonth }}
          </span>
          <span v-else-if="item.prices.vacation_rent !='0'">
            {{ item.prices.vacation_rent }} {{ item.vacation_rental_currency }}
          </span>
      </div>

        <div class="info">
            <h3>
                <a style="color:rgb(54, 52, 53); cursor:pointer;" @click="goToProperty(item.folio, item.namePropertyEs)">

                    {{ item.namePropertyEs }}
                </a>
                <small> <span v-if="item.colony!= 0">{{ item.colony}}, </span>
                {{ item.city}}
                </small>
            </h3>
            <p class="descrip" > {{item.descriptionEs}}</p>
            <ul class="amenities">
              <li v-if="item.m2c != 0">
                <i class="icon-area"></i> {{ item.m2c }} m<sup>2</sup>
              </li>
              <li v-else-if="item.mlot != 0">
                <i class="icon-area"></i> {{ item.mlot }} m<sup>2</sup>
              </li>
              <li v-if="item.bedrooms!='0'" >
                <i class="icon-bedrooms"></i> {{ item.bedrooms }}
              </li>
              <li v-if="item.bathrooms!='0'" >
                <i class="icon-bathrooms"></i> {{ item.bathrooms }}
              </li>
            </ul>
        </div>
    </div>
    <!--- END COL -->
</template>

<script>
    import { mapGetters } from "vuex";
    import services from "@/store/_services";
   export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mainPrice: 0,
      secondPrice: 0,
      mainOperation: 0,
      secondOperation: 0,
    };
  },
  computed: {
    ...mapGetters({
      info:'_getInfoCompany',
    }),
    priceInfo() {
      //Formateo de decimales
      let decimalPrice = this.mainPrice.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = this.mainPrice.indexOf("MXN");
      let currentPriceU = this.mainPrice.indexOf("USD");

      // Precio formateado value.substr(currentPrice)
      let formatPrice = this.mainPrice.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += this.mainPrice.substr(currentPriceM);
      } else {
        formatPrice += this.mainPrice.substr(currentPriceU);
      }
      return formatPrice;
    },
  },
  
  methods: {
    goToProperty() {
      let namePropFilt = services.normalize(this.item.namePropertyEs);
      let folio = this.item.folio;
      this.$router.push({
        name: "Property",
        params: {
          folio,
          namePropFilt,
        },
      });
    },
  },
  created() {
    /* For Base Template*/
    let prices = this.item.prices;
    let operations = this.item.operations;
    if (prices.opportunity_sold) {
      this.mainPrice = prices.opportunity_sold;
      this.mainOperation = operations.opportunity_sold;
      this.secondPrice = prices.sold;
      this.secondOperation = operations.sold;
    } else if (prices.opportunity_rent) {
      this.mainPrice = prices.opportunity_rent;
      this.mainOperation = operations.opportunity_rent;
      this.secondPrice = prices.rent;
      this.secondOperation = operations.rent;
    } else if (prices.sold) {
      this.mainPrice = prices.sold;
      this.mainOperation = operations.sold;
    } else if (prices.rent) {
      this.mainPrice = prices.rent;
      this.mainOperation = operations.rent;
    }
  },
};
</script>

<style >
    #contain_tarjet_property img {
        width: 100%;
    }
    .list-style .item{
      max-width: 100% !important;
      flex: auto!important;
    }
    @media (max-width: 992px) {
        #contain_tarjet_property {
            width: 65%;
        }
    }

    @media (max-width: 520px) {
        #contain_tarjet_property {
            width: 100%;
        }
    }
   #contain_tarjet_property img{
      height: 213px !important;
      object-fit: cover;
    }
    .descrip{
      /* min-height: 48px; */
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      font-size:13px;
    }
    .amenities{
      border-color: #000!important;
    }
    .amenities i{
      color:#141a20!important;
    }
</style>
