<template>
  <main>
    <Preloader v-if="!prop" />
    <div v-else>
      <Banner :image="prop.images[0].largefile" :pageTitle="activeLocale == 'es' || !prop.propertyNameEn
        ? prop.propertyNameEs
        : prop.propertyNameEn
        " />
      <div class="content">
        <div class="container">
          <div class="row">
            <div class="main-2 col-sm-8">

              <div class="property-single-item property-main">

                <!-- PROPERTY TITLE -->
                <h1 v-if="prop.propertyNameEs" class="property-title mt-title text-black">
                  {{ prop.propertyNameEs }}
                  <small v-if="prop.address">{{ prop.city }}</small>
                </h1>
                <!-- BEST INFORMATION -->
                <div class="property-topinfo">
                  <ul v-if="prop.bedrooms != '0' ||
                    prop.bathrooms != '0' ||
                    prop.m2c != '0' ||
                    prop.toperation != '0'
                    " class="amenities">
                    <!-- <li v-if="prop.toperation">
                  <i class="icon-apartment"></i><b>En {{ prop.toperation}}</b>
                 </li> -->
                    <li v-if="prop.typepropertyEs">
                      <i class="icon-house"></i> {{ prop.typepropertyEs }}
                    </li>
                    <li v-if="prop.m2c">
                      <i class="icon-area"></i> {{ prop.m2c }} m<sup>2</sup>
                    </li>
                    <li v-if="prop.mlot">
                      <i class="icon-area"></i> {{ prop.mlot }} m<sup>2</sup>
                    </li>
                    <li v-if="prop.bedrooms">
                      <i class="icon-bedrooms"></i> {{ prop.bedrooms }}
                    </li>
                    <li v-if="prop.bathrooms">
                      <i class="icon-bathrooms"></i> {{ prop.bathrooms }}
                    </li>
                    <li v-if="prop.nameTower">{{ $t("pages.property_detail.nameTower") }}<span style="color:#cc2922">{{
                      prop.nameTower }}</span></li>
                  </ul>
                  <div v-if="prop.folio != '0'" id="property-id" class="folio">
                    Folio: # {{ prop.folio }}
                  </div>
                </div>

                <!-- PRICE  -->

                <div id="property-detail-wrapper" class="style1">
                  <div class="row price">
                    <div v-if="prop.operation.sold != 0" class="col-md-12">

                      {{ prop.operation.sold }}

                      <span :class="[
                        prop.operation.opportunity_sold != 0 ? 'not-price' : '',
                        'text-white h4 mb-1',
                      ]">
                        {{ prop.prices.sold }}
                      </span>
                      <b id="leyendaPrecios" class="text-uppercase" style="font-size:10px;">{{
                        $t("components.property_card.legend") }} </b>
                      <div v-if="prop.pagoCredito == 1">
                        <b id="leyendaCredito" class="text-uppercase" style="font-size:10px;">
                          *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                        </b>
                      </div>
                    </div>
                    <div v-if="prop.operation.rent != 0" class="col-md-6">

                      {{ prop.operation.rent }}

                      <span :class="[
                        prop.operation.opportunity_rent != 0 ? 'not-price' : '',
                        'text-success h1 mb-1',
                      ]">
                        {{ prop.prices.rent }}
                      </span>
                      <b id="leyendaPrecios" class="text-uppercase" style="font-size:10px;">{{
                        $t("components.property_card.legend") }} </b>
                      <div v-if="prop.pagoCredito == 1">
                        <b id="leyendaCredito" class="text-uppercase" style="font-size:10px;">
                          *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                        </b>
                      </div>
                    </div>
                  </div>
                  <div class="row price">
                    <div v-if="prop.operation.opportunity_sold != 0" class="col-md-6">

                      {{ prop.operation.opportunity_sold }}

                      <span>
                        {{ prop.prices.opportunity_sold }}
                      </span>
                      <b id="leyendaPrecios" class="text-uppercase" style="font-size:10px;">{{
                        $t("components.property_card.legend") }}</b>
                      <div v-if="prop.pagoCredito == 1">
                        <b id="leyendaCredito" class="text-uppercase" style="font-size:10px;">
                          *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                        </b>
                      </div>
                    </div>
                    <div v-if="prop.operation.opportunity_rent != 0" class="col-md-6">

                      {{ prop.operation.opportunity_rent }}

                      <span>
                        {{ prop.prices.opportunity_rent }}
                      </span>
                      <b id="leyendaPrecios" class="text-uppercase" style="font-size:10px;">{{
                        $t("components.property_card.legend") }}</b>
                      <div v-if="prop.pagoCredito == 1">
                        <b id="leyendaCredito" class="text-uppercase" style="font-size:10px;">
                          *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                        </b>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="price">
                    <i class="fa fa-home"></i>{{item.operation}}
                    <span v-if="item.prices.sold!='0'">
                      {{ item.prices.sold }} 
                    </span>
                    <span v-else-if="item.prices.opportunity_rent !='0'">
                      {{ item.prices.opportunity_rent }} {{ item.currennamePerMonth }}
                    </span>
                    <span v-else-if="item.prices.rent !='0'">
                      {{ item.prices.rent }} {{ item.currennamePerMonth }}
                    </span>
                    <span v-else-if="item.prices.vacation_rent !='0'">
                      {{ item.prices.vacation_rent }} {{ item.vacation_rental_currency }}
                    </span>
                </div> -->
                </div>

                <DetailCarousel2 :items="prop.images" />
                <!-- CAROUSEL -->
                <!-- <DetailCarousel :items="prop.images" /> -->
                <br />

              </div>




              <!-- PROPERTY FLAYERS -->
              <!-- BEING IMMO CONTENT -->
              <h2> <b>{{ $t("pages.property_detail.share_title") }} </b></h2>
              <hr>
              <div>
                <h4 style="color:#000;">Banners</h4>
                <ul class="buttons-content">
                  <li v-if="prop.banner1">
                    <a class="banner-button" target="_blank" :href="prop.banner1">
                      <i class="fa fa-file-image-o"></i> 1 Foto
                    </a>
                  </li>
                  <li v-if="prop.banner3">
                    <a class="banner-button" target="_blank" :href="prop.banner3">
                      <i class="fa fa-file-image-o"></i> 3 Fotos
                    </a>
                  </li>
                  <li v-if="prop.banner4">
                    <a class="banner-button" target="_blank" :href="prop.banner4">
                      <i class="fa fa-file-image-o"></i> 4 Fotos
                    </a>
                  </li>
                  <li v-if="prop.banner6">
                    <a class="banner-button" target="_blank" :href="prop.banner6">
                      <i class="fa fa-file-image-o"></i> 6 Fotos
                    </a>
                  </li>
                </ul>

                <br>
                <h4 v-if="prop.flyerEs || prop.flyerEn" style="color:#000;">FLYERS</h4>
                <ul class="buttons-content">
                  <li v-if="prop.flyerEs">
                    <a class="banner-button" target="_blank" :href="prop.flyerEs">
                      <i class="fa fa-file-pdf-o"></i> FLYER ESPAÑOL
                    </a>
                  </li>
                  <li v-if="prop.flyerEn">
                    <a class="banner-button" target="_blank" :href="prop.flyerEn">
                      <i class="fa fa-file-pdf-o"></i> FLYER ENGLISH
                    </a>
                  </li>
                </ul>
              </div>
              <br>
              <br>
              <!-- BEING SHARE IN SOCIAL NETWORK -->
              <h2> <b>COMPARTE EN REDES SOCIALES</b></h2>
              <hr>
              <div class="social_areaProp">
                <ul>
                  <li><a class="facebook" data-toggle="tooltip" data-placement="top" title="Facebook" :href="`https://www.facebook.com/sharer/sharer.php?u=${url}`
                    " target="_blank"><i class="fab fa-facebook" aria-hidden="true"></i></a></li>
                  <li><a class="twitter" data-toggle="tooltip" data-placement="top" title="Twitter"
                      :href="`https://twitter.com/home?status=${url}`" target="_blank"><i class="fab fa-twitter"
                        aria-hidden="true"></i></a></li>
                  <li><a class="linkedin" data-toggle="tooltip" data-placement="top" title="Linkedin"
                      :href="`https://www.linkedin.com/sharing/share-offsite/?url=${url}`" target="_blank"><i
                        class="fab fa-linkedin" aria-hidden="true"></i></a></li>
                  <li><a class="gmas" data-toggle="tooltip" data-placement="top" title="Google Plus"
                      :href="`https://plus.google.com/share?url=${url}`" target="_blank"><i class="fab fa-google-plus"
                        aria-hidden="true"></i></a></li>
                  <li><a class="pinterest" data-toggle="tooltip" data-placement="top" title="Pinterest"
                      :href="`https://www.pinterest.com.mx/pin/create/button/?url=${url}`" target="_blank"><i
                        class="fab fa-pinterest" aria-hidden="true"></i></a></li>
                  <span class="whatsButton">
                    <h4>Comparte en redes sociales
                      <a class="contact-links" data-toggle="tooltip" data-placement="top" :href="`https://api.whatsapp.com/send?text=${$t(
                        'pages.property_detail.share_whats_message_public'
                      )}: ${url}`
                        " target="_blank">
                        <i style="float:none; margin-left:15px;font-size:25px;line-height:40px;"
                          class="fab fa-whatsapp"></i>
                      </a>
                    </h4>
                  </span>
                </ul>
              </div>
              <br>
              <br>
              <!-- END SHARE IN SOCIAL NETWORK -->

              <!-- BEING DESCRIPTION -->
              <h1><b> {{ $t("pages.property_detail.description_prop") }} </b></h1>
              <hr>
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" v-if="prop.descriptionEs != '0'">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home"
                    aria-selected="true"><b> Español </b></a>
                </li>
                <li class="nav-item" v-if="prop.descriptionEn != '0'">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab"
                    aria-controls="profile" aria-selected="false"><b> English </b></a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <div class="description">
                    <p>
                    <pre> {{ prop.descriptionEs }}</pre>
                    </p>
                  </div>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <div class="description">
                    <p>
                    <pre> {{ prop.descriptionEn }}</pre>
                    </p>
                  </div>
                </div>

              </div>

              <!-- AMENITIES -->
              <section class="border-top py-4"
                v-if="prop.interiorAmenities || prop.otherAmenities || prop.exteriorAmenities || prop.services || prop.developmentServices">

                <div class="container mb-3" v-if="prop.interiorAmenities || prop.otherAmenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black">{{ $t("pages.property_detail.amenities_interior") }}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.hall || prop.interiorAmenities.sala">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_sala") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.kitchen || prop.interiorAmenities.cocina">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_cocina") }}
                    </p>
                    <p class="amenidades col-md-4"
                      v-if="prop.interiorAmenities.dinning_room || prop.interiorAmenities.comedor">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_comedor") }}
                    </p>
                    <p class="amenidades col-md-4"
                      v-if="prop.interiorAmenities.service_area || prop.interiorAmenities.area_servicio">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_areadeservicio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.amueblado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_amueblado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cuartoTv">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_cuartoTV") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.estudio">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_estudio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.cocinaIntegral">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_cocinaIntegral") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoLavado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_ctoLavado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ctoServBaño">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_ctoServBano") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.area_servicio">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_ctoServBano") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.aa">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_aa") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.minisplit">
                      <i class="fas fa-check"></i>
                      Mini split
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.ventiladores">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_ventiladores") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.calentadorAgua">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_calentadorAgua") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.casaInteligente">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_casaInteligente") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.microondas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_microondas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.lavaPlatos">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_lavaPlatos") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.camaraFria">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.int_camaraFria") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.interiorAmenities.central">
                      <i class="fas fa-check"></i>
                      Central
                    </p>
                  </div>
                </div>

                <div class="container mb-3" v-if="prop.exteriorAmenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black"> {{ $t("pages.property_detail.amenities_exterior") }}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.balcon">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_balcon") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.terraza">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_terraza") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jardin">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_jardin") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.alberca">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_alberca") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.elevador">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_elevador") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.estacionamiento">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_estacionamiento") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bbq">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_bbq") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.bar">
                      <i class="fas fa-check"></i>
                      Bar
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.gimnasio">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_gimnasio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.muelle">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_muelle") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cisterna">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_cisterna") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.jacuzzi">
                      <i class="fas fa-check"></i>
                      Jacuzzi
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.plantaElectrica">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_plantaElectrica") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cercoElectrico">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_cercoElectrico") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.puertaMuLock">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_puertaMuLock") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.almacen">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_almacen") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.panelesSolares">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_panelesSolares") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaManiobras">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_areaManiobras") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.casetaSeguridad">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_casetaSeguridad") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasMetalicas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_cortinasMetalicas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.areaDescarga">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_areaDescarga") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.cortinasAnticiclonicas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_cortinasAnticiclonicas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.techado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_techado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.solarium">
                      <i class="fas fa-check"></i>
                      Solarium
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.exteriorAmenities.patio_servicio">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ext_patioservicio") }}
                    </p>
                  </div>
                </div>

                <div class="container mb-3" v-if="prop.developmentAmenities">
                  <div class="row mb-3">
                    <h4 class="h4 text-black"> {{ $t("pages.property_detail.amenities_maintenance") }}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.condominio">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_condominio") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.cafetera">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_cafetera") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubGolf">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_clubGolf") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.casaClub">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_casaClub") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.alberca_d">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_alberca_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.muelle_d">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_muelle_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.clubPlaya">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_clubPlaya") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.spa">
                      <i class="fas fa-check"></i>
                      SPA
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.gimnasio_d">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_gimnasio_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.canchaTenis">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_canchaTenis") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.juegosInf">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_juegosInf") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.lobby">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_lobby") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.elevador_d">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_elevador_d") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.salonEventos">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_salonEventos") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.restaurante">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_restaurante") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.comercios">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_comercios") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.bodega">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_bodega") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.seguridad">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_seguridad") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.accesoControlado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_accesoControlado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servicioAdmin">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_servicioAdmin") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.centroNegocios">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_centroNegocios") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.albercaAlquiler">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_albercaAlquiler") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.servConcierge">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_servConcierge") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.otherServices">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_otherServices") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2constructionDev">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_m2constructionDev") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.m2greenArea">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_m2greenArea") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.palapa">
                      <i class="fas fa-check"></i>
                      Palapa
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentAmenities.marina">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.man_marina") }}
                    </p>
                  </div>

                </div>

                <div class="container mb-3" v-if="prop.developmentServices || prop.services">
                  <div class="row mb-3">
                    <h4 class="h4 text-black"> {{ $t("pages.property_detail.services_prop") }}</h4>
                  </div>
                  <div class="row">
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.mantenimiento">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_mantenimiento") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.servTransp">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servTransp") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.tour">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_tour") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.tiendaComestibles">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_tiendaComestibles") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.servLimpieza">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servLimpieza") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.servAgua">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servAgua") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.servLocalCel">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servLocalCel") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.rampasSillasRuedas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_rampasSillasRuedas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.luz">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_luz") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.agua">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_agua") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.gas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_gas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.circuitoCerrado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_circuitoCerrado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.energiaSustentable">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_energiaSustentable") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.alumbrado">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_alumbrado") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.cableTV">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_cableTV") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.wifi">
                      <i class="fas fa-check"></i>
                      Wi-Fi
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.concierge">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_concierge") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.mascotas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_mascotas") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.fumar">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_fumar") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.sistemaDeAlarma">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_sistemaDeAlarma") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.servTelefono">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_servTelefono") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.camarasDeSeguridad">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_camarasDeSeguridad") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.drenaje">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_drenaje") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.pavimento">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_pavimento") }}
                    </p>
                    <p class="amenidades col-md-4" v-if="prop.developmentServices.poolRentas">
                      <i class="fas fa-check"></i>
                      {{ $t("pages.amenities.ser_poolRentas") }}
                    </p>
                  </div>
                </div>

              </section>
              <br />


              <!-- BEING CHARACTERISTICS INFORMATION -->
              <div class="caracteristicas" v-if="prop.address || prop.referenceEs">
                <h1 class="section-title"><b>CARACTERÍSTICAS DE LA PROPIEDAD</b></h1>
                <div style="position:relative;">
                  <ul class="property-features col-md-6">
                    <li v-if="prop.address">
                      <i class="icon-sale-sign"></i>Dirección: {{ prop.address }}
                    </li>

                  </ul>
                  <ul class="property-features col-md-6">
                    <li v-if="prop.referenceEs">
                      <i class="icon-house-usd"></i>Referencia: {{ prop.referenceEs }}
                    </li>
                    <!-- <li v-if="property.descMuebles!=0">    
                                    <i class="icon-rooms"></i>{{property.descMuebles}}    
                                </li> -->
                    <!-- <li v-if="property.mJardin">    
                                    <i class="icon-garden"></i>Metros de jardín : {{prop.garden}} m<sup>2</sup>   
                                </li>     -->
                  </ul>
                </div>
              </div>
              <!-- END CHARACTERISTICS INFORMATION -->
              <!-- VIDEO -->
             
             
              <section class="mb-3">
                  <div v-if="prop.video" class="mb-4">
                  <div class="col-md-12 no-gutters mt-3 mb-5">
                    <div class="col-12">
                      <h2 class="text-center mb-5">
                        {{ $t("pages.property_detail.video") }}
                      </h2>
                    </div>
                    <div class="col-12">
                      <iframe width="100%" height="315" :src="prop.video" frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                      </iframe>
                    </div>
                  </div>
                </div>
              </section>
               <br>

              <!--apartado detalles de pago --> 
              <div v-if="prop.pagoCredito == 1 || prop.formaPago != 0 || prop.formaPago != '' ||  prop.finaciamiento != 0 || prop.finaciamiento != '' || 
              prop.precioFinanciado != 0 || prop.precioFinanciado != '' || 
              prop.plazoMesesDesde != 0 || prop.plazoMesesDesde != '' || 
              prop.plazoMesesHasta != 0 || prop.plazoMesesHasta != '' || 
              prop.enganche != 0 || prop.enganche != '' || 
              prop.saldoDiferido != 0 || prop.saldoDiferido != '' || 
              prop.saldoContraEntrega != 0 || prop.saldoContraEntrega != '' || 
              prop.obervacaciones != 0 || prop.obervacaciones != '' 
              " class="my-5">
                <h2> <b>DETALLES DE PAGO</b></h2>
                <hr>
                <div class="container">
                  <div class="row price">
                    <div class="col-12 div-inner p-4 " v-if="prop.operation.sold != 0">
                      <h3 class="text-uppercase" style="font-size: 3rem;">PROPIEDAD EN <span style="color:#253c86;">{{prop.operation.sold}}</span></h3>
                      <span :class="[
                      prop.operation.opportunity_sold != 0 ? 'not-price' : '',
                      'h2 mb-1 price-inner',]">
                      {{ prop.prices.sold }} <br>
                      <b id="leyendaPrecios" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                      {{ $t("components.property_card.legend") }} <br>
                      <div v-if="prop.pagoCredito == 1">
                      <b id="leyendaCredito" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                        *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                      </b> <br />
                      <b v-if="prop.pagoCredito == 1" id="leyendaCreditoTexto" class=" mb-3" style="font-size:12px;">
                        *En las operaciones de Crédito el precio total se determinará en función
                        de los montos variables de conceptos de crédito y gastos notariales que deben
                        de ser consultados con los promotores.
                      </b>
                    </div>
                    </b>
                    </span>
                    </div>
                    <div class="col-12 div-inner p-4 " v-if="prop.operation.rent != 0">
                      <h3 class="text-uppercase" style="font-size: 3rem;">PROPIEDAD EN <span style="color:#253c86;">  {{ prop.operation.rent }}</span></h3>
                      <span :class="[
                      prop.operation.opportunity_rent != 0  ? 'not-price' : '',
                      'h2 mb-1 price-inner',]">
                      {{ prop.prices.rent }} <br>
                      <b id="leyendaPrecios" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                      {{ $t("components.property_card.legend") }} <br>
                      <div v-if="prop.pagoCredito == 1">
                      <b id="leyendaCredito" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                        *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                      </b> <br />
                      <b v-if="prop.pagoCredito == 1" id="leyendaCreditoTexto" class=" mb-3" style="font-size:12px;">
                        *En las operaciones de Crédito el precio total se determinará en función
                        de los montos variables de conceptos de crédito y gastos notariales que deben
                        de ser consultados con los promotores.
                      </b>
                    </div>
                    </b>
                    </span>
                    </div>
                    <div class="col-12 div-inner p-4 " v-if="prop.operation.opportunity_sold != 0">
                      <h3 class="text-uppercase" style="font-size: 3rem;">PROPIEDAD EN <span style="color:#253c86;">  OPORTUNIDAD VENTA</span></h3>
                      <span :class="[
                      prop.operation.opportunity_sold != 0  ? 'not-price' : '',
                      'h2 mb-1 price-inner',]">
                      {{ prop.prices.opportunity_sold }} <br>
                      <b id="leyendaPrecios" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                      {{ $t("components.property_card.legend") }} <br>
                      <div v-if="prop.pagoCredito == 1">
                      <b id="leyendaCredito" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                        *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                      </b> <br />
                      <b v-if="prop.pagoCredito == 1" id="leyendaCreditoTexto" class=" mb-3" style="font-size:12px;">
                        *En las operaciones de Crédito el precio total se determinará en función
                        de los montos variables de conceptos de crédito y gastos notariales que deben
                        de ser consultados con los promotores.
                      </b>
                    </div>
                    </b>
                    </span>
                    </div>
                    <div class="col-12 div-inner p-4 " v-if="prop.operation.opportunity_rent != 0">
                      <h3 class="text-uppercase" style="font-size: 3rem;">PROPIEDAD EN <span style="color:#253c86;">  OPORTUNIDAD RENTA</span></h3>
                      <span :class="[
                      prop.operation.opportunity_rent != 0  ? 'not-price' : '',
                      'h2 mb-1 price-inner',]">
                      {{  prop.prices.opportunity_rent }} <br>
                      <b id="leyendaPrecios" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                      {{ $t("components.property_card.legend") }} <br>
                      <div v-if="prop.pagoCredito == 1">
                      <b id="leyendaCredito" class="text-uppercase leyendas mb-3" style="font-size:12px;">
                        *ESTA PROPIEDAD ACEPTA PAGO A CRÉDITO
                      </b> <br />
                      <b v-if="prop.pagoCredito == 1" id="leyendaCreditoTexto" class=" mb-3" style="font-size:12px;">
                        *En las operaciones de Crédito el precio total se determinará en función
                        de los montos variables de conceptos de crédito y gastos notariales que deben
                        de ser consultados con los promotores.
                      </b>
                    </div>
                    </b>
                    </span>
                    </div>
                  </div>


                <!-- apartado tabla  -->
                  <div class="row mt-5 pagos-table">
                    <div
                      v-if="(typeof prop.formaPago === 'number' && prop.formaPago !== 0) || (typeof prop.formaPago === 'string' && prop.formaPago.trim() !== '')"
                      class="col-6 col-md-4  mt-4  text-center">
                      <div class="div-inner">
                        <h4 class="pb-2">Forma de Pago</h4>
                        <span>{{ prop.formaPago }}</span>
                      </div>

                    </div>
                    <div
                      v-if="(typeof prop.finaciamiento === 'number' && prop.finaciamiento !== 0) || (typeof prop.finaciamiento === 'string' && prop.finaciamiento.trim() !== '')"
                      class="col-6 col-md-4  mt-4 text-center">
                      <div class="div-inner">
                        <h4 class="pb-2">Financiamiento</h4>
                        <span> {{ prop.finaciamiento }} </span>
                      </div>
                      
                    </div>

                    <div
                      v-if="(typeof prop.precioFinanciado === 'number' && prop.precioFinanciado !== 0) || (typeof prop.precioFinanciado === 'string' && prop.precioFinanciado.trim() !== '')"
                      class="col-6 col-md-4  mt-4  text-center">
                      <div class="div-inner">
                        <h4 class="pb-2">Precio financiado</h4>
                        <span> $ {{ prop.precioFinanciado }} </span>
                      </div>
                    
                    </div>

                    <div v-if="(prop.plazoMesesDesde !== null && prop.plazoMesesDesde !== undefined && prop.plazoMesesDesde !== 0) ||
                      (typeof prop.plazoMesesDesde === 'string' && prop.plazoMesesDesde.trim() !== '') &&
                      (prop.plazoMesesHasta !== null && prop.plazoMesesHasta !== undefined && prop.plazoMesesHasta !== 0) ||
                      (typeof prop.plazoMesesHasta === 'string' && prop.plazoMesesHasta.trim() !== '')"
                      class="col-6 col-md-4  mt-4  text-center">
                      <div class="div-inner pb-2">
                        <h4 class="pb-2">Plazos</h4>
                      <div class="container p-0 m-0">
                        <div class="row p-0 m-0">
                          <div class="col-8 p-0 m-0 ">
                            <span v-if="(prop.plazoMesesDesde !== null && prop.plazoMesesDesde !== undefined && prop.plazoMesesDesde !== 0) ||
                              (typeof prop.plazoMesesDesde === 'string' && prop.plazoMesesDesde.trim() !== '')"> 
                              Desde: {{ prop.plazoMesesDesde }} 
                            </span> 
                            <br>
                            <span v-if="(prop.plazoMesesHasta !== null && prop.plazoMesesHasta !== undefined && prop.plazoMesesHasta !== 0) ||
                              (typeof prop.plazoMesesHasta === 'string' && prop.plazoMesesHasta.trim() !== '')">
                              Hasta: {{ prop.plazoMesesHasta }} 
                            </span>

                          </div>
                          <div class="col-4  text-left inline p-0 m-0">
                            <span class="months-middle ">meses</span>
                          </div>
                        </div>

                      </div>
                      </div>

                    </div>

                    <div
                      v-if="(typeof prop.enganche == 'number' && prop.enganche !== 0) || (typeof prop.enganche == 'string' && prop.enganche.trim() !== '')"
                      class="col-6 col-md-4  mt-4  text-center">
                      <div class="div-inner">
                        <h4 class="pb-2">Enganche</h4>
                      {{ prop.enganche }} %
                      </div>
            
                    </div>

                    <div
                      v-if="(typeof prop.saldoDiferido === 'number' && prop.saldoDiferido !== 0) || (typeof prop.saldoDiferido === 'string' && prop.saldoDiferido.trim() !== '')"
                      class="col-6 col-md-4  mt-4  text-center">
                      <div class="div-inner">
                        <h4 class="pb-2">Saldo diferido</h4>
                      {{ prop.saldoDiferido }} %
                      </div>
                   
                    </div>

                    <div
                      v-if="(typeof prop.saldoContraEntrega === 'number' && prop.saldoContraEntrega !== 0) || (typeof prop.saldoContraEntrega === 'string' && prop.saldoContraEntrega.trim() !== '')"
                      class="col-6 col-md-4 mt-4  text-center">
                      <div class="div-inner">
                        <h4 class="pb-2">Saldo contra entrega</h4>
                      {{ prop.saldoContraEntrega }} %
                      </div>
             
                    </div>
                    <div v-if="(typeof prop.obervacaciones === 'string' && prop.obervacaciones.trim() !== '')"
                      class="col-12 col-md mt-4">
                      <div class="div-inner">
                        <h4 class="pb-2">Observaciones</h4>
                      <p class="p-3 m-0">{{ prop.obervacaciones }} </p>
                      </div>
      
                    </div>
                  </div>
                </div>
              </div>

              <h2 class="section-title first">
                <b>INFORMACIÓN DEL AGENTE </b>
              </h2>
              <div class=" mt-5 ">
                <div style="text-align: center" class="col-sm-6">
                  <h2><b>{{ prop.agent.name }}</b></h2>
                  <hr>
                  <p class="mb-4 text-left">
                    <a class="" style="color:#000!important;"> <i class="fa fa-envelope"
                        style="color:#253c86 !important; text-align: left !important;"></i> : {{ prop.agent.email }}</a>
                  </p>
                  <p class="mb-0 text-left">
                    <a class="" data-toggle="tooltip" data-placement="top" title="Contactar en WhatsApp" target="_blank"
                      style="color:#000!important;" :href="`https://api.whatsapp.com/send?text=${$t(
                        'pages.property_detail.share_whats_message_agent'
                      )}: ${url}&phone=52${prop.agent.cellphone}`
                        ">
                      <i class="fa fa-phone" style="color:#253c86 !important; text-align: left !important;"></i>: {{
                        prop.agent.cellphone }}
                    </a>
                  </p>

                </div>
                <div class="col-sm-6">
                  <h2 class="text-center">CONTACTO</h2>
                  <ContactForm2 />
                </div>
              </div>

            </div>

            <div class=" mt-3 col-sm-4">
              <!-- CONTACT -->


              <div class="sidebar gray">
                <h2 class="section-title text-black"><b>{{ $t("layout.search_form.search_props") }} </b></h2>
                <SearchFormVer />
              </div>



              <!-- LOCATION -->
              <div class="mb-5 mt-5" v-if="prop.latitude || prop.longitude">
                <div class="col-md-12 no-gutters mb-5">
                  <div class="col-12">
                    <h2 class="section-title">
                      {{ $t("pages.property_detail.map_title") }}
                    </h2>
                  </div>
                  <div class="col-12 ">
                    <GoogleMap :mapLat="prop.latitude" :mapLng="prop.longitude" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import DetailCarousel from "@/components/carousel/DetailCarousel.vue";
import DetailCarousel2 from "@/components/carousel/DetailCarousel2.vue";
import Banner from "@/components/layout/Banner.vue";
import Preloader from "@/components/layout/Preloader.vue";
import SearchForm from "@/components/search/SearchForm.vue";
import ContactForm2 from "@/components/layout/ContactForm2.vue";
import GoogleMap from "@/components/layout/GoogleMap.vue";
import SearchFormVer from "@/components/search/SearchFormVer.vue";

export default {
  components: {
    Banner,
    Preloader,
    GoogleMap,
    SearchForm,
    ContactForm2,
    DetailCarousel,
    DetailCarousel2,

    SearchFormVer,
  },
  data() {
    return {
      folio: "",
      agentId: 0,
      url: "",
      amenities: [],
    };
  },
  computed: {
    ...mapGetters({
      prop: "_getProperty",
    }),
    activeLocale() {
      return this.$i18n.locale;
    },
    format(val) {
      let value = val.prop.bestprice[0];
      //Formateo de decimales
      let decimalPrice = value.lastIndexOf(".");
      //Extaracción de moneda
      let currentPriceM = value.indexOf("MXN");
      let currentPriceU = value.indexOf("USD");
      // Precio formateado value.substr(currentPrice)
      let formatPrice = value.substr(0, decimalPrice) + " ";
      if (currentPriceM != "-1") {
        formatPrice += value.substr(currentPriceM);
      } else {
        formatPrice += value.substr(currentPriceU);
      }
      return formatPrice.slice(1);
    },
  },
  created() {
    this.folio = this.$route.params.folio;
    if (this.$route.params.agentId) {
      this.agentId = this.$route.params.agentId;
    }
    let data = {
      folio: this.folio,
      agent: this.agentId,
    };
    this.$store.dispatch("getProperty", data);
    this.url = this.$store.state.URL_SITE + this.$route.path;
    //Send Data to Access
    if (process.env.NODE_ENV === "production") {
      this.$getLocation().then((coordinates) => {
        let accessData = {
          lat: coordinates.lat,
          lng: coordinates.lng,
          url: this.url,
          propertyId: this.folio,
        };
        this.$store.dispatch("setPropertyClick", accessData);
      });
    }
    this.sliderStyle();
  },
  methods: {
    sliderStyle() {
      setTimeout(function () {
        /*----------------------------------------------------*/
        /*  owlCarousel
          /*----------------------------------------------------*/
        if ($(".nonloop-block-13").length > 0) {
          $(".nonloop-block-13").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            autoplay: true,
            margin: 20,
            nav: false,
            dots: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                margin: 20,
                stagePadding: 0,
                items: 1,
              },
              1000: {
                margin: 20,
                stagePadding: 0,
                items: 2,
              },
              1200: {
                margin: 20,
                stagePadding: 0,
                items: 3,
              },
            },
          });
        }
        if ($(".slide-one-item").length > 0) {
          $(".slide-one-item").owlCarousel({
            center: false,
            items: 1,
            loop: true,
            stagePadding: 0,
            margin: 0,
            autoplay: true,
            pauseOnHover: false,
            nav: true,
            animateOut: "fadeOut",
            animateIn: "fadeIn",
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
          });
        }
        if ($(".nonloop-block-4").length > 0) {
          $(".nonloop-block-4").owlCarousel({
            center: true,
            items: 1,
            loop: false,
            margin: 10,
            nav: true,
            navText: [
              '<span class="icon-arrow_back">',
              '<span class="icon-arrow_forward">',
            ],
            responsive: {
              600: {
                items: 1,
              },
            },
          });
        }
      }, 400);
      return;
    },

    // nextImage() {

    //     var active = this.activeImage + 1;

    //     if (active >= this.photos.length) {

    //         active = 0;

    //     }

    //     this.activateImage(active);

    // },

    // // Go backwards on the images array

    // // or go at the last image

    // prevImage() {

    //     var active = this.activeImage - 1;

    //     if (active < 0) {

    //         active = this.photos.length - 1;

    //     }

    //     this.activateImage(active);

    // },

    // activateImage(imageIndex) {

    //     this.activeImage = imageIndex;

    // },
  },
};
</script>

<style scoped>
.for-ul {
  column-count: 3 !important;
  /* border-bottom:  solid 1px #a7a7a7 ; */
  padding-bottom: 15px;
}

.mb-5 {
  margin-bottom: 20px;
}

.mt-5 {
  margin-top: 20px;
}

.section-title,
.section-highlight {
  padding-bottom: 15px;
  position: relative;
  text-align: center;
  clear: both;
  display: inline-block;
  width: 100%;
}

.section-title,
.section-highlight {
  margin-bottom: 20px;
  margin-top: 30px;
}

.share-wraper {
  border: 1px solid #e3e3e3;
  margin-bottom: 0px !important;
  padding: 0px 18px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  text-align: center;
}

.banner {
  display: block;
  margin-top: 20px !important;
  list-style-type: none;
  padding: 0 !important;
  overflow: hidden;
  text-align: center;
  margin-bottom: 10px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
  /* try */
}

.banners {
  float: left;
}

.title-bf {
  color: #4d4f52;
  margin-top: 10px;
  margin-bottom: 0;
}

.banner-button {
  display: block;
  width: auto;
  border-radius: 5px;
  padding: 7px;
  margin: 5px;
  text-decoration: none;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
  border: 1px solid #919191;

  color: #4d4f52;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -ms-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s;
  background-color: #f1f3f6;
  display: block;
  color: #000;
  width: 100%;
  border: 2px solid #000;
  border-radius: 4px;
  padding: 10px;
  margin: 5px !important;
}

.banner-button:hover {
  border: 2px solid #d79b2b;
  border-radius: 4px;
  padding: 10px;
  margin: 5px !important;

  color: #d79b2b;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
}

.not-price {
  color: #616161 !important;
  text-decoration: line-through;
}

strong {
  color: #74777c !important;
  font-size: 36px;
  font-weight: 400;
}

.mt-0 {
  margin-top: -5px;
}

.folio {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #253c86;
}

.first {
  margin-bottom: 0 !important;
  margin-top: 0px !important;
}

.not-price {
  font-size: 20px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Raleway", sans-serif !important;
  color: #141313 !important;
  font-weight: 300 !important;
  margin: 0 !important;
  /*text-rendering: optimizelegibility;*/
}

.mt-title {
  margin-top: 40px !important;
}

.property-title {
  text-transform: none !important;
  font-size: 24pt !important;
  margin-bottom: 40px !important;
  margin-top: 54px !important;
}

.sidebar.gray .section-title {
  color: #4d4f52 !important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

.buttons-content,
.social_areaProp ul {
  list-style-type: none;
  padding: 0;
  overflow: hidden;
  text-align: center;
  list-style: none !important;
}

.buttons-content li,
.social_areaProp li {
  float: left;
  margin-left: 5px;
}

.fa-facebook {
  color: #3b5998;
  font-size: 20px !important;
}

.fa-twitter {
  color: #1da1f2;
  font-size: 20px !important;
}

.fa-linkedin {
  color: #0b7bb5;
  font-size: 20px !important;
}

.fa-google-plus {
  color: #d53824;
  font-size: 20px !important;
}

.fa-pinterest {
  color: #cb1f25;
  font-size: 20px !important;
}

.fa-whatsapp {
  color: #000;
}

.tab-content>.active {
  background-color: transparent;
  border: none;
  border-top-color: currentcolor;
  border-top-style: none;
  border-top-width: medium;
  border-top: none;
  padding: 25px 15px;
}

.description p {
  color: #fff;
  text-align: justify !important;
}

pre {
  white-space: pre-wrap;
  height: auto;
  overflow: hidden;
  background: transparent;
  border-color: transparent;
  color: #000;
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  text-align: justify;
}

.property-single-item.property-main {
  padding: 10px 0px;
}

.property-single-item {
  margin-bottom: 45px;
  background: white;
  padding: 30px;
}

h4 {
  font-family: 'Open Sans', sans-serif !important;
}

rounded-desing {
  border: 1px solid #000;
  /* Ancho, estilo y color del borde */
  padding: 10px;

}


/* for table pagos */
/* .pagos-table {
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  padding: 2rem;
  border-radius: 0.4375rem;
} */
.div-inner h3{
  font-weight: bold !important;
  font-family: 'Open Sans' !important;
}
.pagos-table h4 {
  font-weight: bold !important;
  padding-top:2rem;
  text-align: center;
}

.months-middle {
  vertical-align: -moz-middle-with-baseline;
  /* vertical-align: middle; */
}
.div-inner{
  background: #f5f5f6;
  border-radius: 1rem;
  box-shadow: 0rem 0.375rem 1.5rem 0rem rgba(140, 152, 164, 0.125);
  padding-bottom: 3rem;
}
.leyendas{
  font-size: 12px;
  font-family: 'Roboto';
  color: #74777c;
}
.price-inner{
  font-weight: bold;
  color:rgb(37, 60, 134);
  font-size:3rem;
}
</style>
